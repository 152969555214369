<template>
  <div class="container">
    <br />
    <br />
    <h1 class="title has-text-light">Suivi des lots</h1>
    <h2 class="subtitle has-text-light">Il reste {{reste}} lots à gagner</h2>
    <div class="lot box" v-for="lot in lots">
      <b v-html="lot.nom"></b>
      - Reste {{lot.quantite}}
    </div>
    <h1 class="title has-text-light">Derniers gains attribués</h1>
    <div class="box" v-for="gain in gains">
      <span v-html="gain.nom"></span>
    </div>
    <div class="actions">
      <button @click="version" class="button is-warning">Mettre à jours les lots dans le jeu</button>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      reste: 0,
      gains: [],
      lots: [],
    };
  },
  mounted() {
    this.stats();
    setInterval(this.stats, 5000);
  },
  methods: {
    stats() {
      console.log("chargement des stats");
      api.get("gains/").then((response) => {
        this.gains = response.data.gains;
        this.lots = response.data.lots.lots;
        this.reste = 0;
        this.lots.forEach((lot) => {
          this.reste += lot.quantite;
        });
      });
    },
    version() {
      api.post("setup/").then((response) => {
        this.$store.dispatch("chargerLots", { callback: this.stats });
      });
    },
  },
};
</script>

<style lang="scss">
.lot br {
  display: none;
}

html,
body,
body #app {
  min-height: 100vh;
  height: initial;
}

.actions {
  position: fixed;
  bottom: 10px;
  left: 10px;
  > * {
    margin: 0 !important;
  }
}
</style>